<template>
  <v-dialog v-model="dialog" persistent width="700px">
    <v-card v-if="!!entranceSession">
      <v-card-title>
        {{ entranceSession.id ? "Editar" : "Nova" }} Sessão de Entrada
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form v-model="valid" @submit.prevent>
          <v-row dense class="mx-0">
            <v-col cols="12">
              <v-text-field
                v-model="entranceSession.name"
                label="Nome"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                :disabled="loading"
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="entranceSession.startDate"
                label="Horário Inicial"
                type="datetime-local"
                :disabled="loading"
                clearable
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="entranceSession.endDate"
                label="Horário Final"
                type="datetime-local"
                :disabled="loading"
                clearable
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <base-editor
                label="Orientações Gerais"
                v-model="entranceSession.guidelines"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="approvalCondition"
                label="Aprovação de entrada"
                hint="Habilita a aprovação de entrada para os ingressos"
                class="mt-0"
                dense
                :disabled="loading"
              ></v-switch>
              <v-text-field
                v-if="approvalCondition"
                v-model="entranceSession.approvalCondition"
                label="Condição de Aprovação"
                hint="O que o colaborador deve verificar para aprovar a entrada"
                :disabled="loading"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                class="mt-2"
                outlined
                dense
                counter="100"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <h6>Validar os seguintes lotes:</h6>
              <v-data-table
                :disabled="loading"
                v-model="entranceSession.TicketBlocks"
                item-key="id"
                :headers="headers"
                :items="ticketBlocks"
                :loading="loadingTicketBlocks"
                group-by="ticketGroupId"
                show-select
                dense
                single-expand
                :items-per-page="-1"
                hide-default-footer
                :search="search"
                :custom-filter="searchFn"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Pesquisar"
                    hide-details
                    outlined
                    dense
                    clearable
                    class="pa-1"
                  ></v-text-field>
                </template>

                <template
                  v-slot:group.header="{
                    group,
                    toggle,
                    isOpen,
                    headers,
                    isMobile,
                  }"
                >
                  <td :colspan="isMobile ? 1 : headers.length">
                    <div class="d-flex align-center">
                      <v-simple-checkbox
                        :value="groups[group].selected === groups[group].total"
                        :indeterminate="
                          groups[group].selected !== 0 &&
                          groups[group].selected !== groups[group].total
                        "
                        @click="toggleGroup(group)"
                      ></v-simple-checkbox>
                      <h6 class="mb-0 ml-4" @click="toggle(group)">
                        {{ reducedGroups[group] }}
                      </h6>
                      <v-spacer />
                      <v-btn
                        @click="toggle(group)"
                        class="mr-2 flex-shrink-1"
                        icon
                      >
                        <v-icon v-if="isOpen">mdi-chevron-down</v-icon>
                        <v-icon v-else>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </template>
                <template v-slot:item.price="{ item }">
                  {{ item.price | currency }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
        <v-alert v-if="error" type="error" text>
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-space-between pb-4">
        <v-btn text @click="close(false)" :disabled="loading"> Cancelar </v-btn>
        <v-btn
          color="primary"
          @click="save"
          :disabled="!valid || !entranceSession.TicketBlocks.length || loading"
          :loading="loading"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import PARTY from "@/services/admin/party";
import TICKET from "@/services/admin/ticket";

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      error: false,
      ticketBlocks: [],
      ticketGroups: [],
      search: "",
      loadingTicketBlocks: false,
      entranceSession: this.defaultSessionObj(),
      approvalCondition: false,
      headers: [
        {
          text: "Setor",
          value: "ticketGroupId",
        },
        {
          text: "Lote",
          value: "name",
          class: "px-0",
          cellClass: "px-2",
        },
        {
          text: "Valor",
          value: "price",
          align: "end",
          cellClass: "font-weight-medium",
        },
      ],
    };
  },
  methods: {
    searchFn(_, search, i) {
      const query = search.toLowerCase();
      return (
        i.name.toLowerCase().includes(query) ||
        i.ticketGroupName.toLowerCase().includes(query)
      );
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        const { id, organizationId } = this.party;
        const { entranceSession } = this;
        const data = {
          name: entranceSession.name,
          startDate: entranceSession.startDate,
          endDate: entranceSession.endDate,
          guidelines: entranceSession.guidelines,
          ticketBlocks: entranceSession.TicketBlocks.map((tb) => tb.id),
          approvalCondition: this.approvalCondition
            ? entranceSession.approvalCondition
            : null,
        };

        if (!entranceSession.id) {
          await PARTY.entrance.session.create(organizationId, id, data);
        } else {
          await PARTY.entrance.session.update(
            organizationId,
            id,
            entranceSession.id,
            data
          );
        }

        this.close(true);
      } catch (error) {
        this.error = error.message || "Erro ao salvar a sessão de entrada";
      } finally {
        this.loading = false;
      }
    },
    toggleGroup(group) {
      const { selected, ticketBlocks } = this.groups[group];
      const allSelected = selected === this.groups[group].total;

      if (allSelected) {
        this.entranceSession.TicketBlocks =
          this.entranceSession.TicketBlocks.filter(
            (tb) => !ticketBlocks.find((t) => t.id === tb.id)
          );
      } else {
        this.entranceSession.TicketBlocks = [
          ...this.entranceSession.TicketBlocks,
          ...ticketBlocks,
        ].filter(
          (tb, index, self) => index === self.findIndex((t) => t.id === tb.id)
        );
      }
    },
    open(data) {
      this.entranceSession = Object.assign(
        {},
        this.defaultSessionObj(),
        data || {}
      );

      if (data) {
        this.entranceSession.startDate = data.startDate
          ? moment(data.startDate).local().format("YYYY-MM-DDTHH:mm")
          : null;
        this.entranceSession.endDate = data.endDate
          ? moment(data.endDate).local().format("YYYY-MM-DDTHH:mm")
          : null;

        this.entranceSession.TicketBlocks = this.ticketBlocks.filter((tb) =>
          data.TicketBlocks.find((t) => t.id === tb.id)
        );
      }
      this.approvalCondition = !!this.entranceSession.approvalCondition;
      this.dialog = true;
    },
    close(type = false) {
      this.dialog = false;
      this.$emit("close", type);
      this.entranceSession = Object.assign({}, this.defaultSessionObj());
    },
    async getTicketBlocks() {
      try {
        const orgId = this.party?.organizationId;
        this.loadingTicketBlocks = true;

        const { ticketGroups } = await TICKET.getTickets(orgId, this.party.id, {
          childrenParty: true,
        });

        this.ticketGroups = ticketGroups;
        this.ticketBlocks = ticketGroups.reduce((acc, tg) => {
          const tbs = tg.TicketBlock.map((tb) => ({
            id: tb.id,
            name: tb.name,
            price: tb.price,
            ticketGroupName: tg.name,
            ticketGroupId: tg.id,
            ticketGroup: {
              id: tg.id,
              name: tg.name,
              party: tg.party,
              ticketBlocks: tg.TicketBlock,
            },
          }));
          return [...acc, ...tbs];
        }, []);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTicketBlocks = false;
      }
    },
    defaultSessionObj() {
      const { party } = this;
      var guidelines = "";
      if (party) {
        if (party?.ageGroup === 0)
          guidelines = "**Faixa Etária:** Livre para todos os públicos.";
        else {
          guidelines = `**Faixa Etária:** Este evento é para maiores de **${party.ageGroup} anos**.`;
          if (party.ageWithParent) {
            guidelines += ` Jovens **${party.ageWithParent} ${
              party.ageGroup - party.ageWithParent >= 2 ? "ou" : "e"
            } ${
              party.ageGroup - 1
            } anos** podem entrar acompanhados pelos pais ou responsáveis ou com uma carta de autorização assinada pelos mesmos.`;
          }
        }
        if (party.requireDocuments)
          guidelines += `\n\n**Documentos:** ${party.requireDocuments}`;

        if (party.dressCode)
          guidelines += `\n\n**Normas de vestimenta:** ${party.dressCode}`;
      }

      return {
        name: "",
        guidelines,
        approvalCondition: "",
        startDate: null,
        endDate: null,
        TicketBlocks: [],
      };
    },
  },
  computed: {
    groups() {
      if (!this.ticketGroups.length) return {};

      return this.ticketGroups.reduce((acc, tg) => {
        if (!acc[tg.id])
          acc[tg.id] = { total: 0, selected: 0, ticketBlocks: [] };

        tg.TicketBlock.forEach((tb) => {
          acc[tg.id].total += 1;
          if (this.entranceSession.TicketBlocks.find((t) => t.id === tb.id))
            acc[tg.id].selected += 1;
          acc[tg.id].ticketBlocks.push(tb);
        });

        return acc;
      }, {});
    },
    reducedGroups() {
      return this.ticketGroups.reduce((acc, tg) => {
        if (!acc[tg.id]) {
          var name = tg.name;
          if (tg.Party.id !== this.party.id) name += ` (${tg.Party.name})`;

          acc[tg.id] = name;
        }
        return acc;
      }, {});
    },
  },
  mounted() {
    this.$root.$on("entrance-session-modal", this.open);
    if (!this.ticketBlocks.length) this.getTicketBlocks();
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
